<template>
  <v-row
    class="d-flex flex-column"
    style="position: relative; right: 0; top: -11px"
  >
    <div class="counter d-flex flex-row justify-end pr-3 ">
      <span
        v-if="maxLength"
        id="df-counter"
        :class="inputLength > maxLength ? 'df-counter-error' : ''"
      >
        {{ inputLength }} / {{ maxLength }}
      </span>
    </div>

    <div
      :id="idName.toLowerCase().replace(/\s/g, '')"
      class="b2b-data-field pl-2 mt-1"
      contenteditable="true"
      @input="updateValue"
      @keydown.enter.prevent
      :ref="`field_${fieldName}`"
    >
    </div>

    <v-col class="text-right pa-0 pr-2 mb-0">
      <v-menu>
        <template v-slot:activator="{ on }">
          <v-btn
            id="df-selector"
            :title="$vuetify.lang.t('$vuetify.addVariables')"
            class="df-suggested-field-menu-button pr-2"
            text
            v-on="on"
            @click="savePositionWhereAddChip()"
          >
            <v-icon
              class="mb-1"
              color="var(--primary-alternative)"
              small
            >
              {{ const_icons.ADD }}
            </v-icon>
            <span class="text-add-var">{{ $vuetify.lang.t('$vuetify.addVariables') }}</span>
          </v-btn>
        </template>

        <v-list
          class="cts-process-params-add-list"
          dense
          subheader
        >
          <v-list-item
            v-for="(field, index) in suggestedFields"
            :key="index"
          >
            <a
              :id="`df-selector-${field}`"
              class="cts-link"
              @click="addChip(field, positionCursor)"
            >
              {{ $vuetify.lang.t(`$vuetify.${field}`) }}
            </a>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
        valueInput : {{ valueInput }}
  </v-row>
</template>

<script>
import const_icons from "@/constants/icons";
import ChiptextDataField
  from "@/components/sections/processLaunch/models/commonComponents/dataFields/chipTextDataField.vue"
import Vue from "vue";

export default {
  name: 'textDataFieldNew',
  props: {
    idName: {
      type: String,
      default: ''
    },
    fieldName: {
      type: String,
      default: ''
    },
    maxLength: {
      type: Number
    },
    suggestedFields: {
      type: Array,
      default: () => {
        return [];
      }
    },
    value: {
      type: String,
      default: ''
    },
    suggestedValues: {}
  },

  data() {
    return {
      const_icons,
      positionCursor: {},
      valueInput: '',
      chipPattern: /%%([a-zA-Z_][a-zA-Z0-9_]*)%%/g
    };
  },

  created() {
    this.valueInput = this.value
  },

  mounted() {
    this.formatStringToPaintChips(this.valueInput)
  },

  updated() {
    this.cleanBRtags()
  },

  computed: {
    inputLength() {
      return this.value ? this.replaceChipsForVariableLength(this.value).length : 0
    },
  },

  methods: {
    getEditableArea(){
      return this.$refs[`field_${this.fieldName}`]
    },

    cleanBRtags(){
      const editableArea = this.getEditableArea

      if (editableArea) {
        const observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            mutation.addedNodes.forEach((node) => {
              if (node.nodeName === "BR") {
                observer.disconnect()

                node.remove()

                observer.observe(editableArea, {
                  childList: true,
                  subtree: true,
                  characterData: true
                })
              }
            })
          })
        })

        observer.observe(editableArea, {
          childList: true,
          subtree: true,
          characterData: true
        })
      }
    },

    translateNameChip(idChip) {
      return this.$vuetify.lang.t(`$vuetify.${idChip}`)
    },

    replaceChipsForVariableLength(stringWithChips) {
      let match
      let valueWhithoutChips = stringWithChips

      while ((match = this.chipPattern.exec(stringWithChips)) !== null) {
        const nameChip = match[1]
        const valueVariable = this.suggestedValues[nameChip]

        valueWhithoutChips = valueWhithoutChips.replace(match[0], valueVariable)
      }

      return valueWhithoutChips
    },

    savePositionWhereAddChip() {
      this.positionCursor = this.getPositionCursor()
    },

    getPositionCursor() {
      const selection = window.getSelection()
      return selection.getRangeAt(0)
    },

    formatStringToPaintChips(inputString) {
      const editableArea = this.getEditableArea()

      if (!editableArea) {
        console.warn("No se encontró el área editable.")
        return
      }

      let match
      let lastIndex = 0

      const fragment = document.createDocumentFragment()

      while ((match = this.chipPattern.exec(inputString)) !== null) {
        const idChip = match[1]

        this.addPrecedingText(inputString, lastIndex, match.index, fragment)

        this.addWhiteSpaceInEditableArea(fragment)

        this.addChipInString(idChip, fragment)

        this.addWhiteSpaceInEditableArea(fragment)

        lastIndex = this.chipPattern.lastIndex
      }

      this.addRemainingText(inputString, lastIndex, fragment)

      editableArea.appendChild(fragment)
    },

    addChipInString(idChip, fragment) {
      const newChip = this.createChips(this.translateNameChip(idChip), idChip)
      const chipContainer = this.createContainerChip()
      chipContainer.appendChild(newChip)
      fragment.appendChild(chipContainer)
    },

    addPrecedingText(inputString, lastIndex, index, fragment) {
      const precedingText = inputString.slice(lastIndex, index)
      if (precedingText) {
        const textNode = document.createTextNode(precedingText)
        fragment.appendChild(textNode)
      }
    },

    addRemainingText(inputString, lastIndex, fragment) {
      const remainingText = inputString.slice(lastIndex)
      if (remainingText) {
        const textNode = document.createTextNode(remainingText)
        fragment.appendChild(textNode)
      }
    },

    addChip(idChip, positionCursor) {
      const editableArea = this.$refs[`field_${this.fieldName}`];

      if (!positionCursor) {
        console.warn("No se encontró una selección o cursor.");
        return
      }

      if (!editableArea.contains(positionCursor.startContainer)) {
        positionCursor = this.putCursorInLastPosition()
      }

      const nameTranslated = this.translateNameChip(idChip)

      const newChip = this.createChips(nameTranslated, idChip)

      const chipContainer = this.createContainerChip()

      this.insertChipInCursorPosition(newChip, positionCursor)

      chipContainer.appendChild(newChip)

      positionCursor.deleteContents()
      positionCursor.insertNode(chipContainer)
      this.addWhitespace(positionCursor)

      positionCursor.setStartAfter(chipContainer)
      positionCursor.setEndAfter(chipContainer)

      this.addWhitespace(positionCursor)

      this.placeCursorAfterChip(newChip)

      this.updateValue()
    },

    createContainerChip() {
      const chipContainer = document.createElement('span')
      chipContainer.style.display = "inline-block"
      chipContainer.classList.add('chip-container')
      return chipContainer
    },

    putCursorInLastPosition() {
      const editableArea = this.getEditableArea()
      const selection = window.getSelection()
      const range = document.createRange()

      const lastChild = editableArea.lastChild

      range.setStartAfter(lastChild)
      range.setEndAfter(lastChild)

      selection.removeAllRanges()
      selection.addRange(range)

      return selection.getRangeAt(0)
    },

    addTag(positionCursor, tag, attributes = {}, styles = {}, content = '') {
      const tagNode = document.createElement(tag);

      for (let key in attributes) {
        tagNode.setAttribute(key, attributes[key]);
      }

      for (let style in styles) {
        tagNode.style[style] = styles[style];
      }

      tagNode.textContent = content

      positionCursor.insertNode(tagNode);
    },

    addCharacter(character, positionCursor) {
      const characterNode = document.createTextNode(character)
      positionCursor.insertNode(characterNode)
    },

    addWhitespace(positionCursor) {
      const whitespaceNode = document.createTextNode("\u00A0")
      positionCursor.insertNode(whitespaceNode);
    },

    createChips(nameChip, idChip) {
      const newChipComponent = Vue.extend(ChiptextDataField);
      const chipInstance = new newChipComponent({
        propsData: {
          name: nameChip,
          value: idChip
        }
      })
      return chipInstance.$mount().$el
    },

    insertChipInCursorPosition(chipElement, positionCursor) {
      positionCursor.insertNode(chipElement)
    },

    placeCursorAfterChip(chipElement) {
      const selection = window.getSelection();
      const range = document.createRange();

      range.setStartAfter(chipElement);
      range.setEndAfter(chipElement);

      selection.removeAllRanges();
      selection.addRange(range);

    },

    checkNBSPInEditableArea() {
      const editableArea = this.getEditableArea()

      const content = editableArea.innerText

      const hasNBSPAtStart = content.startsWith('\u00A0')
      if (!hasNBSPAtStart) {
        this.addWhitespaceStart(this.getEditableArea())
      }

      const hasNBSPAtEnd = content.endsWith('\u00A0')
      if (!hasNBSPAtEnd) {
        this.addWhiteSpaceInEditableArea(this.getEditableArea())
      }
    },

    addWhitespaceStart(editableArea) {
      const testNode = document.createTextNode('\u00A0')
      editableArea.insertBefore(testNode, editableArea.firstChild)
    },

    addWhiteSpaceInEditableArea(fatherNode) {
      const testNode = document.createTextNode('\u00A0')
      fatherNode.appendChild(testNode)
    },

    updateValue() {
      const editableArea = this.getEditableArea()

      if (editableArea && editableArea.innerText) {
        this.checkNBSPInEditableArea()
      }

      this.valueInput = this.getEditableArea().textContent

      if (this.inputEmptyWithTags(editableArea.innerHTML)) {
        this.valueInput = ''
      }

      this.valueInput = this.getEditableArea().textContent

      this.valueInput = this.replaceIdsChips(this.valueInput)

      this.valueInput = this.valueInput.trim()

      this.valueInput = this.cleanWhiteEspacesDuplicated(this.valueInput)

      this.$emit('input', this.valueInput)
    },

    inputEmptyWithTags(inputHTML) {
      const tempDiv = document.createElement('div')
      tempDiv.innerHTML = inputHTML
      const textContent = tempDiv.textContent
      return textContent.trim().length === 0
    },

    replaceIdsChips(text) {
      const regex = /%%([a-zA-Z0-9-_]+)\s*-\s*([a-zA-Z0-9-_ ]+)\s*%%/g
      return text.replace(regex, (match, variableName) => `%%${variableName}%%`);
    },

    cleanWhiteEspacesDuplicated(text) {
      return text.replace(/\s+/g, ' ').trim()
    }
  }

}
</script>

<style lang="scss" scoped>
.b2b-data-field {
  display: inline-block;
  border-radius: 4px;
  padding-top: 4px;
  padding-left: 2px;
  padding-right: 2px;
  height: 28px;
  width: 100%;
  min-width: 100%;
  word-break: break-word;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  cursor: text;
  outline: none;
  font-size: 13px;
  border: solid 1px var(--tertiary);
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#df-counter {
  font-size: 13px;
}

.df-counter-error {
  color: var(--error)
}

.df-text {
  display: inline-block;
  min-width: 5px !important;
  line-height: 24px;
  max-width: 100%;
  white-space: nowrap;
}

.df-text[contenteditable] {
  outline: 0 solid transparent;
  line-height: 24px;
}

.df-tag {
  display: inline-block;
  margin: 2px 0 0 0;
  padding: 0 4px !important;
  font-size: 13px !important;
  font-weight: bolder;
  line-height: 16px;
  height: 20px;
  max-height: 20px;
  min-height: 20px;
  max-width: 100%;
  color: var(--primary) !important;
  background-color: var(--clear) !important;
  border: 1px solid var(--primary) !important;
  border-radius: 4px;
}

.df-suggested-field-menu-button {
  font-size: 13px !important;
  height: 35px !important;
  margin-top: -4px;
  font-weight: bold;
  letter-spacing: normal;
}

.df-suggested-field-menu-button:hover::before {
  opacity: 0 !important;
}

.df-suggested-field-menu-button span {
  color: var(--primary-alternative)
}

.df-suggested-field-menu-button:hover span,
.df-suggested-field-menu-button:hover i {
  color: var(--primary-alternative-focus) !important;
  background-color: transparent !important;
}

.counter {
  height: 19px;
}
</style>